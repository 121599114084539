/* --------------------------------------------------------------

   reset.css
   * Resets default browser CSS.

-------------------------------------------------------------- */

html {
    margin: 0;
    padding: 0;
    border: 0;
}

body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, code,
del, dfn, em, img, q, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, nav, section {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* This helps to make newer HTML5 elements behave like DIVs in older browers */
article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section {
    display: block;
}

button {
    -webkit-appearance: none;
}

/* Line-height should always be unitless! */
body {
    line-height: 130%; /*1.5*/
    background: white;
}

/* Tables still need 'cellspacing="0"' in the markup. */
table {
    border-collapse: separate;
    border-spacing: 0;
}

/* float:none prevents the span-x classes from breaking table-cell display */
caption, th, td {
    text-align: left;
    font-weight: normal;
    float: none !important;
}

table, th, td {
    vertical-align: middle;
}

input, button, select {
    border: none;
    border-radius: 0px;
}

input:disabled, button:disabled {
    background: $colorGrey;
    color: $colorBlue;
}

.disabled {
    color: $colorGrey;
}

textarea {
    resize: vertical;
}

/* DELETE Remove possible quote marks (") from <q>, <blockquote>. */
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
}

blockquote, q {
    quotes: "";
}

/* Remove annoying border on linked images. */
a img {
    border: none;
}

img {
    display: block;
}

/* Remember to define your own focus styles! */
:focus {
    outline: 0;
}
