* {
    box-sizing: border-box;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

html, body {
    position: relative;
    height: 100%;
    overflow-y: auto;
}

body {
    font-family: $fontFamilyMain;
    color: #ffffff;
    font-weight: 300;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background: $colorBackground;
}

.highlight {
    font-style: italic;
}

.svg {
    width: 24px;
    height: 24px;
}

#screenSize {
    position: fixed;
    top: 0;
    top: constant(safe-area-inset-top); // iOs 11
    top: env(safe-area-inset-top); // iOs 11+ (feature)
    bottom: 0;
    bottom: constant(safe-area-inset-bottom); // iOs 11
    bottom: env(safe-area-inset-bottom); // iOs 11+ (feature)
    width: 100%;
    overflow: auto;
}

#wrapper {
    min-height: 100%;
    background: $colorBackground;
}

#content {
    padding-top: 41px;
    padding-bottom: 50px;
}

.margin {
    margin: 15px;
}