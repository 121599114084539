.content-credit-details {
    .topnav {
        background: none;
    }

    #content {
        padding-top: 0;
        height: 100%;
    }

    .template-item {
        .loan-content {
            margin-top: 40px;
        }
    }
}

.loan-detailed-overview .loan-graph .credit-application-label {
    font-size: 0.8rem;
}

.loan-detailed-overview {
    overflow-y: auto;
}

.loan-item {
    display: flex;
    flex-direction: column;

    .buttons-container {
        padding: 0 40px 40px 40px;

        .buttons {
            margin-top: 5px;
            display: flex;
        }

        .flex-loan button {
            margin: 0 10px;
        }
    }

    .loan-graph {
        margin-top: 10px;
    }

    .title {
        font-size: 12px;
        color: #FFFFFF;
        opacity: 0.8;
    }

    .overview-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 40px 0;

        .overview {
            text-align: left;
            margin: 0 10px 10px;
            min-width: 80px;

            .amount {
                font-weight: 600;
                margin: 10px 0;
            }

            .icon-info {
                position: absolute;
                right: -15px;
                top: 3px;
            }

            .progress-bar-container {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.1);
                height: 6px;
                border-radius: 3px;

                .progress-bar {
                    height: 100%;
                    border-radius: 3px;
                }

                #progress_bar_redeemed, #progress_bar_available {
                    background-color: $colorBlueLight;
                }

                #progress_bar_remaining, #progress_bar_withdrawn {
                    background-color: $colorHighlight;
                }

                #progress_bar_withdrawn_pending {
                    background-color: $colorOrangeLight;
                }

                #progress_bar_arrears {
                    background-color: $colorError;
                }
            }
        }
    }
}

.swiper-container {
    .swiper-slide {
        height: auto;
    }

    .swiper-pagination-bullets {
        top: 375px;
        bottom: auto;

        .swiper-pagination-bullet {
            opacity: 1;
            background: #d5d5d5;
            margin: 0px 5px;
        }

        .swiper-pagination-bullet-active {
            background: $colorHighlight;
        }

    }
}

.buttons .button {
    font-family: $fontFamilySecondary;
    height: 50px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 15px;
    width: 100%;
    padding: 0;
}

.button.button-text {
    background: none;
    color: #ff6600;
    font-weight: 400;
    font-family: $fontFamilyMain;
}
