$colorBlue: #232572;
$colorBlueLight: #494CAB;
$colorGrey: #DDDDDD;
$colorOrange: #FF6600;
$colorOrangeLight: #FF8C26;
$colorRed: #D0021B;

$colorBackground: $colorBlue;
$colorHighlight: $colorOrange;
$colorError: $colorRed;

$fontFamilyMain: 'Open Sans', sans-serif;
$fontFamilySecondary: 'CaeciliaLTStd';