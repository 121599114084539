.button {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    background: #ff6600;
    padding: 15px 30px;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 0px;
    font-family: 'Open Sans', sans-serif;

    &.font-size-big {
        font-size: 18px;
    }

    &.rounded {
        border-radius: 5px;
    }

    &.button-small {
        padding: 10px 15px;
    }

    &.action {
        font-weight: normal;
        border: 1px solid #ffffff;
        background: transparent;
        padding: 9px 28px;
        font-size: 12px;
        margin-top: 10px;
    }

    &.fw {
        display: block;
        width: 100%;
    }

    &.alt {
        margin-top: 32px;
        display: inline-block;
        padding: 5px;
        font-size: 13px;
        background: transparent;
        font-weight: 300;
    }

    &.inverse {
        color: #ff6600;
        background-color: #ffffff;
    }

    i {
        margin-right: 5px;
    }
}

.buttonwrap {
    padding-bottom: 32px;
}

.buttons-extra {
    margin-top: 30px;

    .button {
        text-align: left;
        margin: 5px 0 ;
        display: inline-block;
        padding: 5px;
        background: transparent;
        height: 30px;
        width: 100%;
    }
}

.button-list {
    margin: 50px 15px 15px 15px;

    .button {
        display: flex;
        font-family: "CaeciliaLTStd";
        height: 40px;
        border-radius: 5px;
        font-size: 15px;
        line-height: 22px;
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        align-items: center;

        img {
            margin-right: 10px;

            &.flag {
                width: 30px;
                height: 20px;
            }
        }
    }
}