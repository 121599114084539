.topnav {
    text-align: center;
    padding: 5px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    background-color: $colorBackground;

    .title {
        padding-top: 5px;
        font-size: 17px;
        z-index: 5;
        margin: 4px 0;
        font-family: $fontFamilySecondary;
        font-weight: bold;
    }

    .btn-wrapper {
        padding: 2px;
        font-weight: normal;
        width: 70px;

        &.btn-back-wrapper {
            align-self: flex-start;
        }

        &.btn-settings-wrapper {
            align-self: flex-end;
            padding-right: 5px;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;

            svg path.shape {
                fill: currentColor;
            }
        }
    }

}