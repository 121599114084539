$donutSize: 300px;
$glowSize: 450px;

.donut-chart {
    .donut-background {
        .svg {
            width: $donutSize;
            height: $donutSize;
        }

        .glow {
            background: transparent url('../../../../images/donut_background_glow.svg') no-repeat center bottom;
            background-size: $glowSize $glowSize;
            height: $glowSize;
            width: 100%;
            position: absolute;
            bottom: 0;
        }
    }

    .donuts {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(180deg);
        width: 100%;
        height: $donutSize;

        .donut-wrapper {
            width: 100%;
            height: $donutSize;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .info-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $donutSize;
        display: flex;
        justify-content: center;
        align-items: center;

        .info > div {
            font-weight: bold;
            font-family: $fontFamilySecondary;
            margin-top: 10px;

            &.remaining {
                font-size: 26px;
            }

            &.total {
                font-size: 20px;
            }
        }

    }
;
}